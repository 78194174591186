<template>
  <div class="activity css-helper">
    <div class="flex flex-wrap activity-items">
      <div
        class="activity-item flex-item"
        v-for="(item, key) in List"
        :key="key"
      >
        <div>
          <div class="acticity-image">
            <img
              class="cursor-pointer"
              :src="item.images"
              alt=""
              @mousedown.stop="an"
              @mouseup.up="song(item.id)"
            />
          </div>
          <div class="detail">
            <div class="flex flex-justify-between">
              <div class="flex-item flex-item-title">
                <span
                  @mousedown.stop="an"
                  @mouseup.up="song(item.id)"
                  class="cursor-pointer"
                >
                  {{ item.name }}</span
                >
              </div>
              <div class="flex-item state">{{ item.hdstatus }}</div>
            </div>
            <div class="desc">{{ item.introduce }}</div>
            <div class="time">
              活动时间：{{ item.start_time }} 至 {{ item.end_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      v-if="total !== 0"
      class="pagenation"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="4"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      pageindex: 1,
      total: 0,
      List: [],
    };
  },
  mounted() {
    this.goData();
  },
  methods: {
    goData() {
      let query = {
        source: "pc",
        type: "1",
        page: this.pageindex,
        pagesize: 4,
      };
      this.$post("/myLike", query).then((res) => {
        console.log("获取活动",res)
        const data = res.data.data;
        this.List = data;
        this.List.map((item) => {
          item.start_time = moment(1000 * parseInt(item.start_time)).format(
            "YYYY-MM-DD"
          );
          item.end_time = moment(1000 * parseInt(item.end_time)).format(
            "YYYY-MM-DD"
          );
        });
        this.total = res.data.total_count;
      });
    },
    handleCurrentChange(val) {
      this.pageindex = val;
      let query = {
        source: "pc",
        type: "1",
        page: this.pageindex,
        pagesize: 4,
      };
      this.$post("/myLike", query).then((res) => {
        const data = res.data.data;
        this.List = data;
        this.List.map((item) => {
          item.start_time = this.formatDate(item.start_time);
          item.end_time = this.formatDate(item.end_time);
        });
        // this.total = res.data.total_count
      });
    },
    an() {
      //鼠标按下
      this.timeLength = new Date().getTime();
    },
    song(id) {
      //鼠标松开 判断鼠标点击的时间
      this.oldtime = new Date().getTime();
      if (this.oldtime - this.timeLength < 130) {
        this.$router.push(`/activitydetail?id=${id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activity {
  .activity-items {
    margin-bottom: 30px;
    .activity-item {
      padding: 10px;
      box-sizing: border-box;
      width: 50%;
      .acticity-image {
        height: 280px;
      }
      .acticity-image img {
        width: 100%;
        height: 100%;
      }
      .detail {
        padding: 10px;
        border: 1px solid #dddddd;
        border-top: none;
        .desc {
          font-size: 12px;
          color: #999999;
          margin-bottom: 8px;
        }
        .time {
          font-size: 14px;
          color: #999999;
        }
        .state {
          color: #999999;
          font-size: 14px;
        }
      }
    }
  }

  .pagenation {
    text-align: center;
  }
  >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #c19c66 !important;
  }
}
.flex-item-title {
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 340px;
  margin-bottom: 10px;
}
</style>

<style lang="scss"></style>
