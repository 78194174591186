<template>
  <div class="management css-helper">
    <div class="management-title">账户管理</div>
    <div class="content">
      <div
        class="popup"
        :class="{ show: isShow, success: popupCode === 0 }"
      ></div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">
          <div class="flex username flex-justify-between">
            <div class="flex-item">用</div>
            <div class="flex-item">户</div>
            <div class="flex-item">名</div>
          </div>
        </div>
        <div class="flex-item flex-1 flex-item-center">
          <input
            class="input"
            type="text"
            v-model="username"
            readonly="readonly"
            :class="{ activeinput: username !== '' }"
          />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">
          <span class="letter-space">昵</span>称
        </div>
        <div class="flex-item flex-1 flex-item-center">
          <input class="input" type="text" v-model="nickname" />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item"><span class="letter-space">性</span>别</div>
        <div class="flex-item flex-1">
          <el-radio class="radio-label" v-model="sex" :label="1">男</el-radio>
          <el-radio class="radio-label" v-model="sex" :label="2">女</el-radio>
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">真实姓名</div>
        <div class="flex-item flex-1 flex-item-center">
          <input class="input" type="text" v-model="name" />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">身份证号</div>
        <div class="flex-item flex-1 flex-item-center">
          <input type="text" v-model="idNumber" class="input" />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">手机号码</div>
        <div class="flex-item flex-1 flex-item-center">
          <input
            type="text"
            v-model="phone"
            class="input"
            readonly="readonly"
            :class="{ activeinput: phone !== '' }"
          />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">邮箱号码</div>
        <div class="flex-item flex-1 flex-item-center">
          <input type="text" v-model="mail" class="input" />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">所在城市</div>
        <div class="flex-item flex-1 flex-item-center">
          <div class="address-outer flex flex-justify-between">
            <div class="select-outer address-select">
              <select
                v-model="province"
                @change="provinceChange"
                class="select flex-item"
              >
                <option value="" disabled>省</option>
                <option
                  :value="province.id"
                  v-for="(province, index) in provinceList"
                  :key="index"
                >
                  {{ province.name }}
                </option>
              </select>
              <img
                src="../../assets/ucenter/selectdown.png"
                class="select-down"
                alt=""
              />
            </div>
            <div class="select-outer address-select">
              <select
                v-model="city"
                @change="cityChange"
                class="select flex-item"
              >
                <option value="" disabled>市</option>
                <option
                  :value="city.id"
                  v-for="(city, index) in cityList"
                  :key="index"
                >
                  {{ city.name }}
                </option>
              </select>
              <img
                src="../../assets/ucenter/selectdown.png"
                class="select-down"
                alt=""
              />
            </div>
            <div class="select-outer address-select">
              <select v-model="town" class="select flex-item">
                <option value="" disabled>区</option>
                <option
                  :value="town.id"
                  v-for="(town, index) in townList"
                  :key="index"
                >
                  {{ town.name }}
                </option>
              </select>
              <img
                src="../../assets/ucenter/selectdown.png"
                class="select-down"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">所在行业</div>
        <div class="flex-item flex-1 flex-item-center select-outer">
          <select name="" id="" v-model="industry" class="input select">
            <option value="" disabled></option>
            <option
              :value="industry.id"
              v-for="(industry, index) in industryList"
              :key="index"
            >
              {{ industry.name }}
            </option>
          </select>
          <img
            src="../../assets/ucenter/selectdown.png"
            class="select-down"
            alt=""
          />
        </div>
      </div>
      <div class="item flex">
        <div class="text flex-item flex-item-center">
          <span class="letter-space">学</span>历
        </div>
        <div class="flex-item flex-1 flex-item-center select-outer">
          <select name="" id="" v-model="edu" class="input select">
            <option value="" disabled></option>
            <option value="dazhuan">大专</option>
            <option value="benke">本科</option>
            <option value="博士">博士</option>
            <option value="硕士">硕士</option>
          </select>
          <img
            src="../../assets/ucenter/selectdown.png"
            class="select-down"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="btn" @click="modify()">修改</div>
  </div>
</template>

<script>
import { fetch } from "../../api/https";

export default {
  data() {
    return {
      name: "",
      username: "",
      nickname: "",
      sex: "",
      idNumber: "",
      province: "",
      city: "",
      town: "",
      industry: "",
      edu: "",
      phone: "",
      mail: "",
      memberid: "",
      industryList: [],
      provinceList: [],
      cityList: [],
      townList: [],
      popupMsg: "",
      isShow: false,
      popupCode: -1,
    };
  },
  async mounted() {
    let industryList = await fetch("/getIndustry");
    this.industryList = industryList.data;
    let provinceList = await fetch("/getAllRegion");
    this.provinceList = provinceList.data;
    this.accountData();
  },
  methods: {
    async getCityList() {
      let cityList = await fetch(`/getAllRegion?pid=${this.province}`, {
        pid: this.province,
      });
      this.cityList = cityList.data;
    },
    async provinceChange() {
      this.getCityList();
      this.city = "";
      this.town = "";
    },
    async getTownList() {
      let townList = await fetch(`/getAllRegion?pid=${this.city}`, {
        cid: this.city,
      });
      this.townList = townList.data;
    },
    async cityChange() {
      this.getTownList();
      this.town = "";
    },
    accountData() {
      let query = {
        source: "pc",
      };
      this.$post("/ucenter/uinfo", query).then((res) => {
        let data = res.data;
        //用户名
        this.username = data.member_username;
        // 真实姓名
        this.name = data.name;
        // 昵称
        this.nickname = data.member_nickname;
        // 性别
        this.sex = data.member_sex;
        // 身份证
        this.idNumber = data.id_code;
        // 手机号
        this.phone = data.member_tel;
        // 邮箱
        this.mail = data.member_email;
        // 省
        data.member_province === null
          ? (this.province = "")
          : (this.province = data.member_province);
        this.getCityList();
        // 市
        data.member_city === null
          ? (this.city = "")
          : (this.city = data.member_city);
        this.getTownList();
        // 区
        data.member_town === null
          ? (this.town = "")
          : (this.town = data.member_town);
        // 行业
        this.industry = data.industry;
        // 学历
        this.edu = data.edu;
        // 用户Id
        this.memberid = data.member_id;
      });
    },
    modify() {
      let query = {
        source: "pc",
        member_id: this.memberid,
        member_nickname: this.nickname,
        member_sex: this.sex,
        name: this.name,
        id_code: this.idNumber,
        member_email: this.mail,
        member_tel: this.phone,
        member_province: this.province,
        member_city: this.city,
        member_town: this.town,
        industry: this.industry,
        edu: this.edu,
      };
      this.$post("/editUserInfo", query).then((res) => {
        this.popupMsg = res.msg;
        this.popupCode = res.code;
        if (res.code === 0) {
          this.accountData();
        }
        this.showPopupMsg();
        this.toTop();
      });
    },
    showPopupMsg() {
      this.showPopupMessage(this.popupMsg, this.popupCode === 0);
    },
    toTop() {
      let el = document.querySelector("#frame_content_view");
      if (el) {
        el.scrollTop = 0;
      }
    },
  },
};
</script>

<style>
.el-radio__input.is-checked .el-radio__inner {
  background: #666 !important;
  border-color: #888;
}
.el-radio__label {
  font-size: 16px;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #666;
  /* font-size: 15px; */
}
</style>
<style lang="scss" scoped>
.management {
  font-family: "MicrosoftYaHei";
  .management-title {
    font-size: 24px;
    color: #333;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6f0;
    margin-bottom: 26px;
    font-family: " Source Han Sans CN";
    font-weight: 600;
  }
  .item {
    width: 560px;
    margin: 30px auto;
    font-size: 16px;
    .text {
      color: rgba(136, 136, 136, 1);
      display: inline-block;
      padding-right: 28px;
      .letter-space {
        &::after {
          content: "啊啊";
          opacity: 0;
        }
      }
    }
    .radio-label {
      color: rgba(136, 136, 136, 1);
    }

    .input,
    .select {
      outline: none;
      border: 1px solid #e1e6f0;
      color: rgba(51, 51, 51, 1);
      height: 45px;
      font-size: 16px;
      border-radius: 10px;
      padding: 0 20px;
    }
    .input {
      width: 467px;
      box-sizing: border-box;
      height: 45px;
      &.activeinput {
        background-color: #f0f2f7;
      }
    }
    .select-outer {
      position: relative;
      .select {
        appearance: none;
      }
      .select-down {
        position: absolute;
        top: 20px;
        right: 16px;
      }
    }

    .radio {
      margin-right: 6px;
    }
    .address-outer {
      width: 467px;
      .address-select {
        width: 30%;
        font-size: 14px;
        // &:not(:last-child) {
        //   margin-right: 10px;
        //   max-width: 148px;
        //   height: 45px;
        // }
        .select {
          width: 100%;
        }
      }
    }

    .username {
      width: 62px;
    }
  }
  .btn {
    width: 216px;
    height: 48px;
    line-height: 48px;
    margin: 0 auto;
    background-color: #bd955d;
    text-align: center;
    // padding: 10px;
    font-size: 18px;
    color: #fff;
    font-weight: bolder;
    border-radius: 10px;
    margin-bottom: 30px;
    font-family: " SourceHanSansCN";
  }
}
</style>
