<template>
  <div class="password css-helper">
    <div class="container">
      <div class="item flex">
        <div class="num flex-item">1</div>
        <div class="flex-item flex-1">
          <div class="text">原始密码</div>
          <input
            class="input"
            type="text"
            placeholder="请输入您的原始登陆密码"
            v-model="password"
          />
        </div>
      </div>
      <div class="item flex">
        <div class="num flex-item">2</div>
        <div class="flex-item flex-1">
          <div class="text">新密码</div>
          <input
            class="input"
            type="text"
            placeholder="请输入您的新登陆密码"
            v-model="newpassword"
          />
        </div>
      </div>
      <div class="item flex">
        <div class="num flex-item">3</div>
        <div class="flex-item flex-1">
          <div class="text">确认密码</div>
          <input
            class="input"
            type="text"
            placeholder="请确定您的新登陆密码"
            v-model="newpassword2"
          />
        </div>
      </div>
    </div>
    <div class="btn" @click="btn()">确定</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      newpassword: "",
      newpassword2: "",
    };
  },
  mounted() {},
  methods: {
    btn() {
      // if (this.newpassword != this.newpassword2) {
      //   return false;
      // }
      if (this.password === "") {
        this.showPopupMessage("原密码不能为空", false);
      } else if (this.newpassword === "") {
        this.showPopupMessage("新密码不能为空", false);
      } else if (this.newpassword2 === "") {
        this.showPopupMessage("请确认新密码", false);
      } else {
        let query = {
          source: "pc",
          oldpass: this.password,
          newpass: this.newpassword,
          repassword: this.newpassword2,
        };
        this.$post("/modify_pass", query).then((res) => {
          res.code === 1
            ? this.showPopupMessage(res.msg, true)
            : this.showPopupMessage(res.msg);
          if (res.code === 1) {
            setTimeout(() => {
              this.password = "";
              this.newpassword = "";
              this.newpassword2 = "";
            }, 2000);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  .container {
    padding: 0 150px;
    .item {
      margin-bottom: 30px;
      .num {
        background-color: #bd955d;
        text-align: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        line-height: 16px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin: 3px 10px 0 0;
      }
      .text {
        color: #333333;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .input {
        outline: none;
        border: 1px solid #dddddd;
        padding: 3px 20px;
        width: 460px;
        height: 48px;
        font-size: 16px;
        border-radius: 10px;
      }
    }
  }
  .btn {
    width: 216px;
    height: 48px;
    line-height: 48px;
    margin: 0 auto;
    text-align: center;
    background-color: #bd955d;
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bolder;
    border-radius: 10px;
    margin-bottom: 30px;
    font-family: " SourceHanSansCN";
  }
}
</style>