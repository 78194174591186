const TimeCount = 60
const Text = '发送验证码'
export default function () {
    return {
        text: Text,
        countdown: TimeCount,
        countdownTimer: null,
        request() {
            if (this.countdownTimer !== null) {
                return false
            }
            return (done) => {
                done = !!done
                if (done) {
                    this.countdownTimer = setInterval(() => {
                        this.countdown--
                        this.text=`${this.countdown}秒`
                        if (this.countdown === 0) {
                            this.clear()
                        }
                    }, 1000);
                } else {
                    this.clear()
                }
            }
        },
        clear() {
            if (typeof this.countdownTimer === 'number') {
                clearInterval(this.countdownTimer)
                this.countdownTimer = null
            }
            this.countdown=TimeCount
            this.text=Text
        }
    }
}