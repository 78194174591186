<template>
  <div class="safe css-helper">
    <div class="safe-title">账号设置</div>
    <div class="flex flex-justify-center">
      <div
        class="flex-item tag cursor-pointer"
        :class="{ active: currentComponent === 'password' }"
        @click="currentComponent = 'password'"
      >
        修改密码
      </div>
      <div
        class="flex-item tag cursor-pointer"
        :class="{ active: currentComponent === 'phone' }"
        @click="currentComponent = 'phone'"
      >
        修改绑定手机
      </div>
    </div>
    <div :is="currentComponent"></div>
  </div>
</template>

<script>
import password from "./ucenterPassword.vue";
import phone from "./ucenterPhone.vue";
export default {
  data() {
    return {
      currentComponent: "password",
    };
  },
  components: {
    password,
    phone,
  },
};
</script>

<style lang="scss" scoped>
.safe {
  font-family: "MicrosoftYaHei";
  .safe-title {
    font-size: 24px;
    font-weight: 600;
    font-family: " Source Han Sans CN";
    color: #333;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6f0;
    margin-bottom: 26px;
  }
  .tag {
    margin: 0 20px;
    color: #999999;
    margin-bottom: 30px;
    font-size: 17px;
    &.active {
      color: #333;
    }
  }
}
</style>

