<template>
  <div class="phone css-helper">
    <div class="container">
      <div class="item flex">
        <div class="num flex-item">1</div>
        <div class="flex-item flex-1">
          <div class="text" v-show="step">原手机号</div>
          <div class="text" v-show="!step">新手机号</div>
          <input
            class="input"
            type="text"
            v-show="step"
            placeholder="请输入您的11位原手机号"
            v-model="phone"
          /><input
            class="input"
            type="text"
            v-show="!step"
            placeholder="请输入您的11位新手机号"
            v-model="phone"
          />
        </div>
      </div>
      <div class="item flex">
        <div class="num flex-item">2</div>
        <div class="flex-item flex-1">
          <div class="text">安全验证码</div>
          <div class="flex verify-outer">
            <input
              type="text"
              class="flex-item input verify-input"
              v-model="iamgecode"
              placeholder="输入安全验证码"
            />
            <div class="flex-item flex-1 verify verify1">
              <img class="img cursor-pointer" :src="codeImage" alt="" @click="imgCode" />
            </div>
          </div>
        </div>
      </div>
      <div class="item flex">
        <div class="num flex-item">3</div>
        <div class="flex-item flex-1">
          <div class="text">手机验证码</div>
          <div class="flex verify-outer">
            <input
              type="text"
              class="flex-item input verify-input"
              v-model="code"
              placeholder="输入手机验证码"
            />
            <div class="fff">
              <div class="flex-item flex-1 verify verify2 cursor-pointer" @click="sendcode()">
                {{ smsButton.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <div v-show="step" @click="nextstep()">下一步</div>
      <div v-show="!step" @click="determine()">确定</div>
    </div>
  </div>
</template>

<script>
import smsButtonAgent from "../smsButtonAgent";export default {
  data() {
    return {
      userOldPhone: "",
      // 手机号
      phone: "",
      // 图片链接
      codeImage: "",
      // 随机数
      codesubstr: "",
      // 图片input 验证码
      iamgecode: "",
      step: true,
      // 手机验证码 input
      code: "",
      //手机号倒计时
      smsButton: smsButtonAgent(),
      step: true,
    };
  },
  async mounted() {
    this.imgCode();
    this.getPhone();
  },
  methods: {
    async getPhone() {
      let query = {
        source: "pc",
      };
      let res = await this.$post("/ucenter/uinfo", query);
      this.userOldPhone = res.data.member_tel;
    },
    imgCode() {
      let rand = Math.random().toString(12).substr(13);
      // let rand = 123;
      this.codesubstr = rand;
      let query = {
        imgcodeid: rand,
      };
      this.$fetch(`/getVerify?imgcodeid=${rand}`, query).then((res) => {
        this.codeImage =
          "http://www.symuseum.cn/api/getVerify?imgcodeid=" + rand;
      });
    },
    check() {
      var reg_phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (this.phone === "") {
        this.showPopupMessage("手机号不能为空", false);
        return false;
      } else if (this.iamgecode === "") {
        this.showPopupMessage("图片验证码不能为空", false);
        return false;
      } else if (!reg_phone.test(this.phone)) {
        this.showPopupMessage("手机号格式不正确", false);
        return false;
      } else {
        return true;
      }
    },
    checkOldPhone() {
      if (this.step === true) {
        if (this.phone !== this.userOldPhone) {
          this.showPopupMessage("原手机号不正确", false);
          return false;
        } else {
          return true;
        }
      }else{
        return true
      }
    },
    checkCode() {
      if (this.code === "") {
        this.showPopupMessage("手机验证码不能为空", false);
        return false;
      } else {
        return true;
      }
    },
    sendcode() {
      let cb = this.smsButton.request();

      if (cb === false) {
        return;
      }
      if (!this.check()) {
        return;
      }
      if (!this.checkOldPhone()) {
        return;
      }
      let query = {
        phone: this.phone,
        type: "change_phone",
        image_verify_code: this.iamgecode,
        imgcodeid: this.codesubstr,
      };
      this.$post("/send_sms", query).then((res) => {
        if (res.code !== 1) {
          cb(false);
          this.showPopupMessage(res.msg, false);
        } else {
          this.showPopupMessage(res.msg, true);
          cb(true);
        }
      });
    },
    nextstep() {
      if (!this.check()) {
        return;
      }
      if (!this.checkOldPhone()) {
        return;
      }
      if (!this.checkCode()) {
        return;
      }
      let query = {
        source: "pc",
        step: "1",
        oldphone: this.phone,
        old_verification_code: this.code,
        image_verify_code: this.iamgecode,
        imgcodeid: this.codesubstr,
      };
      this.$post("/modify_phone", query).then((res) => {
        if (res.code !== 1) {
          this.showPopupMessage(res.msg, false);
        }
        if (res.code === 1) {
          this.step = false;
          this.phone = "";
          this.iamgecode = "";
          this.code = "";
          this.imgCode();
          this.smsButton.clear();
        }
      });
    },
    determine() {
      if (!this.check()) {
        return;
      }
      if (!this.checkCode()) {
        return;
      }
      let query = {
        source: "pc",
        step: "2",
        newphone: this.phone,
        new_verification_code: this.code,
        image_verify_code: this.iamgecode,
        imgcodeid: this.codesubstr,
      };
      this.$post("/modify_phone", query).then((res) => {
        this.showPopupMessage(res.msg, res.code === 0);
        if (res.code === 0) {
          this.step = true;
          this.phone = "";
          this.iamgecode = "";
          this.code = "";
          this.imgCode();
          this.smsButton.clear();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.phone {
  .container {
    padding: 0 150px;
    .item {
      margin-bottom: 30px;
      .num {
        background-color: #bd955d;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        line-height: 16px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin: 3px 10px 0 0;
      }
      .text {
        color: #333333;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .input {
        outline: none;
        border: 1px solid #dddddd;
        padding: 3px 20px;
        width: 500px;
        height: 48px;
        font-size: 16px;
        border-radius: 10px;
        box-sizing: border-box;
      }
      .verify-outer {
        width: 500px;
        .verify-input {
          width: 350px;
          border-radius: 10px 0 0 10px;
        }
        .verify {
          border: 1px solid #dddddd;
          border-left: none;
          border-radius: 0 10px 10px 0;
          text-align: left;
          height: 46px;
          &.verify1 {
            background-color: #f0f0f0;
          }
          &.verify2 {
            text-align: center;
            width: 150px;
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            color: #333333;
          }
          .img {
            height: 46px;
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
}
.btn {
  width: 216px;
  height: 48px;
  line-height: 48px;
  margin: 0 auto;
  background-color: #bd955d;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-weight: bolder;
  border-radius: 10px;
  margin-bottom: 30px;
  font-family: " SourceHanSansCN";
  div {
    width: 100%;
    height: 100%;
  }
}
</style>
