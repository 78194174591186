<template>
  <div class="exhibition css-helper">
    <div class="flex flex-wrap items">
      <div class="item flex-item" v-for="(item, index) in List" :key="index" v-show="item.id !== null">
        <div>
          <div class="exhibition-image">
            <img
              :src="require('../../assets/img/book/' + item.leftImg)"
              alt=""
              class="img cursor-pointer"
              @click="goDetail(item)"
            />
            <img
              class="img cursor-pointer"
              :src="item.images"
              alt=""
              @click="goDetail(item)"
            />
            <div
              class="left-img-text cursor-pointer flex"
              @click="goDetail(item)"
            >
              <div class="introduce flex-item">{{ item.introduce }}</div>
              <div class="name flex-item">{{ item.name }}</div>
            </div>
          </div>
          <div class="detail">
            <div class="name">
              <span>{{ item.name }}</span>
            </div>
            <div>
              <span class="cursor-pointer" @click="goDetail(item)">
                展览时间：</span
              >
              <span class="time cursor-pointer" @click="goDetail(item)"
                >{{ item.start_time }} 至 {{ item.end_time }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      v-if="total !== 0"
      class="pagenation"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="4"
    >
    </el-pagination>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      pageindex: 1,
      total: 0,
      List: [],
    };
  },
  mounted() {
    this.goData();
  },
  methods: {
    goDetail(item) {
      this.$router.push(`/introduction?id=${item.id}`);
    },
    goData() {
      let query = {
        source: "pc",
        type: "2",
        page: this.pageindex,
        pagesize: 4,
      };
      this.$post("/myLike", query).then((res) => {
        const data = res.data.data;
        this.List = data;
        this.List.map((item, index) => {
          item.start_time = moment(1000 * parseInt(item.start_time)).format(
            "YYYY-MM-DD"
          );
          item.end_time = moment(1000 * parseInt(item.end_time)).format(
            "YYYY-MM-DD"
          );
          item.leftImg = `${index + 1}.png`;
        });
        this.total = res.data.total_count;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibition {
  .items {
    margin-bottom: 30px;
    .item {
      padding: 10px;
      box-sizing: border-box;
      width: 50%;
      .exhibition-image {
        // background: url('../../assets/ucenter/exhibition1.png') center bottom
        //   no-repeat;
        // background-size: contain;
        // height: 400px;
        text-align: center;
        position: relative;
        // padding: 0 20px;
        .left-img-text {
          position: absolute;
          top: 50px;
          left: 60px;
          color: #fff;
          .introduce {
            font-size: 19px;
            width: 19px;
            margin-right: 20px;
          }
          .name {
            font-size: 25px;
            font-weight: bolder;
            width: 25px;
            line-height: 32px;
          }
        }
        .img {
          height: 455px;
          display: inline-block;
        }
      }
      .detail {
        padding: 10px 0;
        color: #999;
        font-size: 14px;
        text-align: left;
        padding-left: 14px;
        .name {
          color: #333;
          font-size: 16px;
          margin-bottom: 3px;
        }
        .time {
          color: #666666;
        }
      }
    }
  }

  .pagenation {
    text-align: center;
  }
}
</style>

<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #c19c66 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #c19c66 !important;
}
</style>
