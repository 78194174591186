<template>
  <div class="like css-helper">
    <div class="like-title">我的收藏</div>
    <div class="flex flex-justify-center top">
      <div
        class="flex-item cursor-pointer"
        @click="currentComponent = 'activity'"
        :class="{ active: currentComponent === 'activity' }"
      >
        <div>活动</div>
        <div :class="{ bottom: currentComponent === 'activity' }"></div>
      </div>
      <div class="flex-item line">|</div>
      <div
        class="flex-item cursor-pointer"
        @click="currentComponent = 'exhibition'"
        :class="{ active: currentComponent === 'exhibition' }"
      >
        <div>展览</div>
        <div :class="{ bottom: currentComponent === 'exhibition' }"></div>
      </div>
    </div>
    <div :is="currentComponent"></div>
  </div>
</template>

<script>
import activity from "./activity.vue";
import exhibition from "./exhibition.vue";
export default {
  data() {
    return {
      currentComponent: "activity",
    };
  },
  components: {
    activity,
    exhibition,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.like {
  .like-title {
    font-size: 24px;
    font-family: " Source Han Sans CN";
    color: #333;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6f0;
    margin-bottom: 26px;
    font-weight: 600;
  }
  .top {
    margin-bottom: 20px;
    color: #888;
    font-size: 20px;
    font-weight: 500;
    .line {
      margin: 0 50px;
    }
    .active {
      color: #333;
    }
    .bottom {
      width: 20px;
      height: 2px;
      background-color: #c19c66;
      margin: 4px auto 0;
    }
  }
}
</style>